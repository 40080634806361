* {
  box-sizing: border-box;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins';
  font-weight: 600;
}

p {
  line-height: 1.5em;
}

body {
  padding: 1em;
  font-family: 'Quicksand';
}

main {
  padding-top: 10vh;
  text-align: center;
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
}
main h1 {
  font-size: 3em;
}

img {
  width: 100%;
}

input, textarea {
  display: block;
  width: 100%;
  border: 2px solid black;
  padding: .5em;
  margin: 1em 0;
  height: 4em;
}

footer {
  padding-top: 3em;
}

button {
  background: white;
  color: black;
  padding: 1em;
  font-size: 1.2em;
  border: 1px solid #000;
  border-radius: 0;
  display: block;
  margin: 0 auto;
  width: 100%;
}
button:hover {
  box-shadow: -3px 3px #2eb8e5;
  -webkit-transform: translate(3px, -3px);
  transform: translate(3px, -3px);
}
button:active {
  box-shadow: none;
  -webkit-transform: none;
  transform: none;
}
nav a:link {
  font-family: 'Poppins';
  font-weight: 600;
}
a:link,a:active,a:hover,a:visited {
  color:black;
  padding: .5em;
}
a:hover {
  background: black;
  color: white!important;
  -webkit-transition: background 1s; /* For Safari */
  transition: background 1s;
}
a.imgLink:hover {
  background: inherit;
  -webkit-transition: none; 
  transition: none;
}
.flexHorizontal {
  display: flex;
  justify-content: center;
  align-content: center;
}

.logoGroup {
  max-width: min-content;
}
.logoGroup:hover, .mobileNavExpanded > span:hover, nav.mobileNav > span:hover {
  cursor: pointer;
}

.logoGroup h1 {
  font-size: small;
  align-self: center;
}

.centeredImage {
  display: block;
  margin: 0 auto;
}

.topMargin2 {
  margin-top: 2em;
}

.topMargin3 {
  margin-top: 3em;
}

.bottomMargin2 {
  margin-bottom: 2em;
}

.bottomMargin3 {
  margin-bottom: 3em;
}

.portfolio img {
  max-width: 600px
}

.fluidFlex {
  display:flex;
  flex-wrap: wrap;
}
.fluidFlex > * {
  flex: 1 1 150px;
  width: 150px;
  object-fit: cover;
  object-position: center;
  margin: .2em
}

.icon img {
  width: 30px;
}
a.icon:hover {
  background: inherit;
  color: inherit;
  -webkit-transition: none;
  transition: none;
}

.smallText {
  font-size: .8rem;
  text-align: center;
}

.emailSentPopUp {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  background-color: #35c4ec;
  color: white;

}
.emailSentPopUp article {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.emailSentPopUp article span {
  position: absolute;
  top: .5em;
  right: 1em;
  font-size: xx-large;
  padding: 0.5em 1em;
}

.mobileNav {display:none}

.mobileNavExpanded {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 5;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
}
.mobileNavExpanded > span {
  position: absolute;
  top: 0.5em;
  right: 1em;
  font-size: xx-large;
  padding: 0.5em 1em;
}


.mobileNav > span {
  font-size: x-large;
  display: inline-block;
  transform: rotate(90deg);

}


@media (max-width: 850px){
  .desktopNav {
    display: none;
  }
  .mobileNav {
    display: block;
  }
}

@media (max-width: 400px) {
  footer nav {
    font-size: 10px;
  }
  footer > div {
    flex-direction: column;
    margin-bottom: 3em !important;
  }
  body {
    padding:0
  }
  main {
    width:100%;
    padding-left:.5em;
    padding-right:.5em;
  }
  main h1 {
    font-size: 28px;
  }
}

